// Copyright 2024 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



import { Router } from "../utils/router.js";
import apiFetch from "../utils/api-fetch.js";
import "../components/inputs/email-input.js";
import "../components/inputs/phonenumber-input.js";



const TPL = create_template();


function create_template()
{
  const e = document.createElement("template");
  e.innerHTML = `
    <style>
      @import url("/pages/contact-us/input-style.css");
      @import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@600&display=swap');

      h2 {
        text-transform: uppercase;
      }

      #main {
        display: flex;
        flex-direction: column;
      }
    </style>

    <div id="main">
      <h2>Coming soon, get notified when we are ready to launch:</h2>

      <div class="page">
        <form class="body">
          <div class="field">
            <label for="fullname">
              Full name
            </label>
            <input class="input" required name="fullname" placeholder="full name" type="text" minlength="3" maxlength="256"/>
          </div>

          <div class="field">
            <label for="phone">
              Phone number
            </label>
            <phonenumber-input class="input" placeholder="+1 234 567 89" name="phone"></phonenumber-input>
          </div>

          <div class="field">
            <label for="email">
              Email address
            </label>
            <email-input class="input" required placeholder="email address" name="email"></email-input>
          </div>
        </form>
      </div>

      <div class="nav-buttons">
        <a class="submit" id="submit">Submit</a>
      </div>
    </div>
  `;
  return e.content;
}



class ContactUs extends HTMLElement {
  #emailMaskito;

  connectedCallback()
  {
    const shadowRoot = this.attachShadow({mode: "open"});
    shadowRoot.appendChild(TPL.cloneNode(true));

    const submitBtnElem = shadowRoot.getElementById("submit");
    submitBtnElem.addEventListener("click", _ => this.#submitClick());
  }

  async #submitClick()
  {
    const form = this.shadowRoot.querySelector("form");
    if (!form.checkValidity()) {
      const invalidElem = this.shadowRoot.querySelector(":invalid:not(form):not(fieldset)");
      console.log("invalidElem:", invalidElem);
      if (invalidElem) { invalidElem.focus(); }
      form.classList.add("validate");
      return;
    }

    form.classList.remove("validate");

    const data = {
      full_name: this.shadowRoot.querySelector("input[name=fullname]").value,
      phone_number: this.shadowRoot.querySelector("phonenumber-input[name=phone]").value || null,
      email: this.shadowRoot.querySelector("email-input[name=email]").value,
    };

    console.log("form data:", data);
    const response = await apiFetch("/api/comingsoon/signup_for_launch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error("response:", response);
      alert("Something went wrong, please try again later.");
      return;
    }

    this.shadowRoot.querySelector("#main h2").innerHTML = `Thank you for signing up! We will notify you when we are ready to launch.`;
    this.shadowRoot.querySelector(".nav-buttons").innerHTML = "";
    form.innerHTML = "";
  }
}


customElements.define("contact-us", ContactUs);
