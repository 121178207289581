// Copyright 2023 Cui Fei (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



export default async function apiFetch(path, options={}, ...args)
{
  const devSetup = ["localhost", "dev-comingsoon.shiningstars.app"].includes(location.hostname)
  const devOnLocalhost = location.hostname === "localhost";

  const hostname = devSetup ?
    "https://dev-api.shiningstars.app" : "https://api.shiningstars.app";

  const headers = options.headers || {};
  if (devOnLocalhost) {
    headers["Authorization"] = `Bearer ${localStorage.getItem("dev_session_id")}`;
  }

  console.log("API hostname", hostname);
  const apiOptions = {
    ...options,
    headers,
    mode: options.mode || "cors",
    credentials: options.credentials || "include",
  };
  const trimPath = path[0] === "/" ? path.slice(1) : path;
  return await fetch(`${hostname}/${trimPath}`, apiOptions, ...args);
}
