// Copyright 2024 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



import { Router } from "../utils/router.js";



const TPL = create_template();


function create_template()
{
  const e = document.createElement("template");
  e.innerHTML = `
    <style>
      footer {
        margin-top: 10rem;
        padding-top: 5rem;
        padding-bottom: 0.25rem;
        background-color: var(--main-section-bg);
      }

      footer p {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 20rem) {
        footer p {
          font-size: 0.6rem;
        }
      }
    </style>

    <footer>
      <p style="text-align: center;">
        By using the website, you agree to the <a route="/privacy-policy">Privacy Policy</a>.
        <br>
        Copyright: © ${(new Date()).getFullYear()} ShiningStars. All rights reserved.
      </p>
    </footer>
  `;
  return e.content;
}



class PageFooter extends HTMLElement {
  #router;

  constructor()
  {
    super();

    const shadowRoot = this.attachShadow({mode: "open"});
    shadowRoot.appendChild(TPL.cloneNode(true));

    this.#router = new Router(shadowRoot, ev => {});
  }

  connectedCallback()
  {
    this.#router.setMountLocation(this.getAttribute("route-mount"));
  }

  disconnectedCallback()
  {
    this.#router.removeMountLocation();
  }
}


customElements.define("page-footer", PageFooter);
