// 2023 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



window.addEventListener("popstate", popStateEvent => {
    const ev = new Event("locationchange");
    ev.state = popStateEvent.state;
    window.dispatchEvent(ev);
})

const oldPushState = history.pushState;
history.pushState = function pushState() {
    let ret = oldPushState.apply(this, arguments);
    window.dispatchEvent(new Event("locationchange"));
    return ret;
};

const oldReplaceState = history.replaceState;
history.replaceState = function replaceState() {
    let ret = oldReplaceState.apply(this, arguments);
    window.dispatchEvent(new Event("locationchange"));
    return ret;
};


export function pushLocation(location, state)
{
    history.pushState(location, "", state);
}

export function replaceLocation(location, state)
{
    history.replaceState(location, "", state);
}


export class Router
{
    #locationChangeCb;
    #mountLocation = null;
    #prevLocation = undefined;
    #rootElem;
    #eventWrapper;

    constructor(rootElem, locationChangeCb)
    {
        this.#locationChangeCb = locationChangeCb;
        this.#rootElem = rootElem;
        this.#eventWrapper = ev => {
            let path = decodeURI(window.location.hash.slice(1));
            if (path[0] != "/") path = "/" + path;
            if (path.startsWith(this.#mountLocation)) {
                path = path.slice(this.#mountLocation.length-1);
            } else {
                path = null;
            }
            if (path !== this.#prevLocation) {
                this.#prevLocation = path;
                locationChangeCb(path, ev?.state);
            }
        }
    }

    get mountLocation()
    {
        return this.#mountLocation;
    }

    get currentPath()
    {
        return this.#prevLocation;
    }

    setMountLocation(mountLocation)
    {
        if (!mountLocation) {
            this.removeMountLocation();
            return;
        }
        if (mountLocation[mountLocation.length-1] !== '/') {
            mountLocation = mountLocation + "/";
        }
        if (this.#mountLocation == mountLocation) { return; }
        this.#mountLocation = mountLocation;
        window.removeEventListener("locationchange", this.#eventWrapper);
        if (this.#mountLocation) {
            this.updateLinks();
            window.addEventListener("locationchange", this.#eventWrapper);
            setTimeout(this.#eventWrapper, 0);
        }
    }

    removeMountLocation()
    {
        window.removeEventListener("locationchange", this.#eventWrapper);
    }


    pushLocation(location, state)
    {
        history.pushState(state, "", this.#expandLocation(location));
    }

    replaceLocation(location, state)
    {
        history.replaceState(state, "", this.#expandLocation(location));
    }

    updateLinks()
    {
        if (!this.#rootElem) return;
        for (const link of this.#rootElem.querySelectorAll("a[route]")) {
            const location = link.getAttribute("route");
            link.setAttribute("href", this.#expandLocation(location));
        }
    }

    #expandLocation(location)
    {
        if (location[0] === "/") {
            return "#" + location;
        } else {
            return "#" + this.#mountLocation + location;
        }
    }
}
