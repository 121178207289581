// Copyright 2024 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



import { Maskito } from "@maskito/core";
import { maskitoPhoneOptionsGenerator } from "@maskito/phone";
import phoneMetadata from "libphonenumber-js/min/metadata";



const phoneNumberMaskOptions = maskitoPhoneOptionsGenerator({
    metadata: phoneMetadata,
    strict: false,
    countryIsoCode: 'US',
});


class PhoneNumberInput extends HTMLElement {
  #inputElem;
  #maskedInput;

  connectedCallback()
  {
    this.#inputElem = document.createElement("input");
    this.#inputElem.style.width = "100%";

    this.#inputElem.setAttribute("placeholder", this.getAttribute("placeholder"));

    this.appendChild(this.#inputElem);

    (async () => {
      this.#maskedInput = new Maskito(this.#inputElem, phoneNumberMaskOptions);
    })();
  }

  disconnectedCallback()
  {
    if (this.#maskedInput) { this.#maskedInput.destroy(); }
  }

  get value()
  {
    return this.#inputElem.value;
  }

  set value(val)
  {
    this.#inputElem.value = val;
  }
}


customElements.define("phonenumber-input", PhoneNumberInput);
