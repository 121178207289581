// Copyright 2024 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



import { Router } from "../utils/router.js";

import "./contact-us.js";


const TPL = create_template();


function create_template()
{
  const e = document.createElement("template");
  e.innerHTML = `
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Rokkitt:wght@600&display=swap');

      .showcase-img {
        width: min(28rem, 80vw);
        height: min(25rem, 70vw);
        object-fit: cover;
        border-radius: 5%;
        margin-right: 4rem;
        margin-left: 0;
      }

      .section-bg:nth-child(odd) .showcase-img {
        margin-right: 0;
        margin-left: 4rem;
      }

      .section-bg:nth-child(2) .section {
        border-top-right-radius: 0;
      }

      .section-bg:last-child .section {
        border-bottom-left-radius: 0;
      }

      .section {
        background-color: #FFF;
        padding-top: 8rem;
        padding-bottom: 8rem;
        border-top-right-radius: 5%;
        border-bottom-left-radius: 5%;
      }

      .section-bg {
        background-color: var(--main-section-bg);
      }

      .section-bg:nth-child(odd) {
        background-color: #FFF;
      }

      .section-bg:nth-child(odd) .section {
        background-color: var(--main-section-bg);
      }

      .content {
        margin-left: auto;
        margin-right: auto;
        max-width: max(min(60rem, 100vw), 50vw);
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        text-align: justify;
        text-justify: inter-character;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.3rem;
      }

      .content h2 {
        text-transform: uppercase;
      }

      #main-panel {
        background: url("/static/main-header-skyline-1280.webp");
        background-size: cover;
        border-top-right-radius: 0;
        padding-top: 3rem;
        height: 50vh;
      }

      #main-panel .content {
        color: #FFF;
        margin-top: 10rem;
        margin-bottom: 8rem;
      }

      #main-panel h1 {
        filter: drop-shadow(1px 1px 5px color-mix(in srgb, #046c89 90%, white));
        font-family: 'Rokkitt', serif;
      }

      @media only screen and (max-width: 30rem) {
        #main-panel h1 {
          font-size: 1.5rem;
        }

        .content {
          flex-wrap: wrap;
        }

        .content img {
          margin: auto;
        }

        .section-bg:nth-child(odd) .showcase-img {
          order: -1;
          margin: auto;
        }
      }

    </style>
    <div>
      <div id="main-panel" class="section">
        <div class="content">
          <h1>Connecting Investors with Constellations of Opportunity</h1>
        </div>
      </div>


      <div class="section-bg">
        <div id="report" class="section">
          <div class="content">
            <contact-us route-mount="/contact-us" style="flex-grow: 1;">
            </contact-us>
          </div>
        </div>
      </div>

    </div>
  `;
  return e.content;
}



class HomePage extends HTMLElement {
  #router;
  #currentView;
  #contactUsElem;

  constructor()
  {
    super();

    const shadowRoot = this.attachShadow({mode: "open"});
    shadowRoot.appendChild(TPL.cloneNode(true));

    this.#router = new Router(shadowRoot, p => this.#locationChange(p));

    this.#contactUsElem = shadowRoot.querySelector("contact-us");

    if (window.innerWidth / window.devicePixelRatio > 1280) {
      const mainPanel = shadowRoot.getElementById("main-panel");
      mainPanel.style.backgroundImage = "url(/static/main-header-skyline-2560.webp)";
    }
  }

  connectedCallback()
  {
    this.#router.setMountLocation(this.getAttribute("route-mount"));
  }

  disconnectedCallback()
  {
    this.#router.removeMountLocation();
  }

  #locationChange(fullPath)
  {
    const [path, ...rest] = fullPath.slice(1).split("/");

    if (path === this.#currentView) return;

    this.#currentView = path;
    switch (path) {
      case "contact-us":
        window.scrollTo(0, this.#contactUsElem.parentNode.parentNode.offsetTop);
      break;
    }
  }
}


customElements.define("home-page", HomePage);
