// Copyright 2024 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



import { Maskito } from "@maskito/core";



const emailMaskOptions = {
    mask: /^[\-_+~a-zA-Z.@]+$/,
};


class EmailInput extends HTMLElement {
  #inputElem;
  #maskedInput;

  connectedCallback()
  {
    this.#inputElem = document.createElement("input");
    this.#inputElem.style.width = "100%";
    //this.#inputElem.setAttribute("type", "email");
    this.#inputElem.minlength = 3;
    this.#inputElem.maxlength = 320;

    if (this.getAttribute("required") !== null) {
      this.#inputElem.required = true;
    }

    this.#inputElem.setAttribute("placeholder", this.getAttribute("placeholder"));

    this.appendChild(this.#inputElem);

    (async () => {
      this.#maskedInput = new Maskito(this.#inputElem, emailMaskOptions);
    })();
  }

  disconnectedCallback()
  {
    if (this.#maskedInput) { this.#maskedInput.destroy(); }
  }

  get value()
  {
    return this.#inputElem.value;
  }

  set value(val)
  {
    this.#inputElem.value = val;
  }
}


customElements.define("email-input", EmailInput);
