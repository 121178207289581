// Copyright 2024 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



import { Router } from "../utils/router.js";



const TPL = create_template();


function create_template()
{
  const e = document.createElement("template");
  e.innerHTML = `
    <style>

      @import url('https://fonts.googleapis.com/css2?family=Moirai+One&display=swap');


      .current-user-unknown #sign-up-menu-item {
        display: none;
      }

      .current-user-unknown #login-menu-item {
        display: none;
      }

      .current-user-unknown #current-user-profile-menu-item {
        display: none;
      }

      .no-current-user #current-user-profile-menu-item {
        display: none;
      }

      .current-user-known #sign-up-menu-item {
        display: none;
      }

      .current-user-known #login-menu-item {
        display: none;
      }

      .menu-item {
        font-size: 1.2rem;
      }

      nav {
        margin: 0;
        padding: 0;
        padding-right: 3rem;
        max-width: 100vw;
      }

      nav#nav-main {
        background-color: #022840;
        border-bottom: 2px solid rgb(51, 90, 166, 1.0);
        transition: background-color 0.5s ease, border-bottom-color 1.0s ease-in-out;
      }

      nav#nav-main ul {
        margin: auto;
      }

      nav#nav-main.nav-seamless {
        background-color: transparent;
        border-bottom-color: rgb(51, 90, 166, 0.0);
        border-bottom: none;
      }

      nav ul {
        height: var(--menu-bar-height);
        margin: 0;
        padding: 0.3rem;
        display: flex;
        align-items: flex-start;
        list-style-type: none;
      }

      nav .menu-item {
        display: flex;
      }

      nav .menu-item a {
        color: #FFF;
        text-decoration: none;
        padding: 0.5rem;
        font-family: 'Rokkitt', serif;
      }

      nav .menu-item a:visited {
        color: #FFF;
      }

      nav .spacer {
        flex-grow: 1;
      }

      nav .separator {
        width: 0.5rem;
      }

      #profile-image {
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 100%;
        border: 1px solid black;
        background-size: cover;
        margin-right: 0.25rem;
      }

      #logo img {
        right: 0;
        height: 3rem;
        margin: 0.25rem;
        display: inline;
        /*filter: brightness(1.5);*/
        transition: height 0.5s ease, filter 0.5s ease, opacity 0.5s ease 0.3s;
      }

      .nav-seamless #logo img {
        height: 5rem;
        /*filter: brightness(1.25);*/
      }

      #logo {
        position: relative;
        padding-left: 0.25rem;
        text-decoration: none;
        color: black;
        display: flex;
        align-items: center;
        align-content: center;
      }

      #logo h1 {
        margin: 0.7rem;
        margin-left: 0.7rem;
        font-family: 'Moirai One', system-ui;
        font-size: 2rem;
        color: yellow;
        transition: font-size 0.5s ease, color 0.5s ease;
      }

      .nav-seamless #stars {
        opacity: 1.0;
      }

      .nav-seamless #dargo {
        opacity: 0.3;
      }

      .nav-seamless #dargo-yellow {
        opacity: 0.0;
      }


      #stars {
        opacity: 0.0;
      }

      #dargo {
        opacity: 0.0;
      }

      #dargo-yellow {
        opacity: 1.0;
      }


      .nav-seamless #logo h1 {
        font-size: 2.5rem;
        color: #FFF;
      }

      #contact-us-menu-item {
        font-size: 1.5rem;
        background-color: #163E73;
        border: 1px solid color-mix(in srgb, #335AA6 80%, white);
        transition: background-color 0.5s ease, border 0.5s ease;
        border-radius: 0.5rem;
        padding: 0.25rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 0.5rem;
        box-shadow: 2px 2px 3px #335AA6;
      }

      #contact-us-menu-item:hover {
        background-color: color-mix(in srgb, #163E73 85%, white);
        border: 1px solid color-mix(in srgb, #335AA6 40%, white);
      }

      @media only screen and (max-width: 30rem) {
        #contact-us-menu-item {
          display: none;
        }

        .nav-seamless #logo img {
          height: 3rem;
        }

        .nav-seamless #logo h1 {
          font-size: 1.6rem;
        }

        #logo img {
          height: 1.5rem;
        }

        #logo h1 {
          font-size: 1.2rem;
        }
      }
    </style>

    <nav id="nav-main" class="nav-seamless">
      <ul>
        <li>
          <a id="logo" route="/">
            <img id="stars" src="/static/dragon-contellation.svg"></img>
            <img id="dargo" src="/static/dragon-contellation.png" style="position: absolute; left: 0.25rem; top: 0.25rem;"></img>
            <img id="dargo-yellow" src="/static/dragon-yellow.webp" style="position: absolute; left: 0.25rem; top: 0.25rem;"></img>
            <h1>Shining Stars</h1>
          </a>
        </li>

        <li class="spacer"></li>

        <li class="menu-item">
          <a id="contact-us-menu-item" route="/contact-us">Contact us</a>
        </li>
      </ul>
    </nav>
  `;
  return e.content;
}



class PageHeader extends HTMLElement {
  #router;
  #navMainElem;
  #logoElem;
  #navigateToMainClickCb;

  static get observedAttributes() { return ["nav-seamless"]; }


  constructor()
  {
    super();

    const shadowRoot = this.attachShadow({mode: "open"});
    shadowRoot.appendChild(TPL.cloneNode(true));

    this.#navMainElem = shadowRoot.getElementById("nav-main");
    this.#logoElem = this.shadowRoot.getElementById("logo");

    this.#router = new Router(shadowRoot, ev => {});

    this.#navigateToMainClickCb = ev => window.scrollTo({top: 0, behavior: "smooth"});
  }

  attributeChangedCallback(name, oldValue, newValue)
  {
    if (name === "nav-seamless" && oldValue !== newValue) {
      if (newValue === "true") {
        this.#navMainElem.classList.add("nav-seamless");
      } else {
        this.#navMainElem.classList.remove("nav-seamless");
      }
    }
  }

  connectedCallback()
  {
    this.#router.setMountLocation(this.getAttribute("route-mount"));

    this.#logoElem.addEventListener("click", this.#navigateToMainClickCb);
  }

  disconnectedCallback()
  {
    this.#router.removeMountLocation();

    this.#logoElem.removeEventListener("click", this.#navigateToMainClickCb);
  }
}


customElements.define("page-header", PageHeader);
