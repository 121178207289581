// Copyright 2024 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



import "./pages/privacy-policy-page.js";
// import "pages/terms-of-use-page.js";
import "./pages/home-page.js";

import "./components/page-header.js";
import "./components/page-footer.js";

import { Router } from "./utils/router.js";


const pageHeaderElem = document.querySelector("page-header");
const pageContentElem = document.getElementById("page-content");
const pageFooterElem = document.querySelector("page-footer");
const router = new Router(document.body, locationChange);
router.setMountLocation("/");

let currentView = null;

function updateNavSeamless()
{
  const seamless = ["home", "questionnaire-demo"].includes(currentView) && window.scrollY < pageHeaderElem.clientHeight;
  pageHeaderElem.setAttribute("nav-seamless", seamless);
}

document.addEventListener("scroll", (event) => {
  updateNavSeamless();
});

function locationChange(fullPath)
{
  const [path, ...rest] = fullPath.slice(1).split("/");

  let nextView = path ? path : "home";
  if (nextView === "contact-us") nextView = "home";
  if (currentView === nextView) return;
  currentView = nextView;

  updateNavSeamless();

  switch (nextView) {
    case "home":
      pageContentElem.innerHTML = `<home-page route-mount="/"></home-page>`;
    break;
    case "privacy-policy":
      pageContentElem.innerHTML = `<privacy-policy-page route-mount="/privacy-policy"></privacy-policy-page>`;
      window.scrollTo(0, 0);
    break;
    /*case "terms-of-use":
        pageContentElem.innerHTML = `<terms-of-use-page route-mount="/terms-of-use"></terms-of-use-page>`;
    break;*/
  }
}
